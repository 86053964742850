<template>
  <div>
    <!-- Match List -->
    <div class="card card-custom gutter-b card-stretch">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder">การทายผลของคุณ</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">ตารางสรุปการทายผลของคุณ</span>
        </h3>
        <div class="card-toolbar">
        </div>
      </div>
      <div class="card-body pt-2">
        <div class="row">
          <div class="col-12 col-sm-6 col-xl-4 p-4">คู่การแข่งขัน</div>
          <div class="col-12 col-sm-6 col-xl-8 p-4 text-info">Date & Time (Timezone: {{ timezone }})</div>
        </div>
        <template v-for="(m, idx) in matches">
          <div v-bind:key="`c${idx}`" v-bind:class="padding(m)">
            <div class="separator separator-solid"></div>
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-xl-4 p-4">
                <div class="h6">{{ m.name }}</div>
                <div class="text-dark-50">{{ m.meta }}</div>
              </div>
              <div class="col-12 col-sm-6 col-xl-2 p-4 text-info">
                <div class="h6 font-weight-bolder">{{ date(m) }}</div>
                <div>{{ time(m) }}</div>
              </div>
              <div class="col-12 col-xl-6 py-4">
                <b-row no-gutters align-h="end">
                  <b-col v-for="(c, k) in m.noteams" v-bind:key="`${idx}-${k}`" class="pr-2 pb-2" cols="*" :sm="4">
                    <b-card no-body class="p-2" v-on:click="openrate(m, c)">
                      <b-row align-v="center" no-gutters>
                        <b-col cols="auto">
                          <div class="symbol symbol-30 symbol-light mr-3 flex-shrink-0">
                            <div class="symbol-label">
                              1
                            </div>
                          </div>
                        </b-col>
                        <b-col class="text-center">
                          <strong>{{ c.name }}</strong>
                          <div>{{ c.rate }}</div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div v-if="teams(m)">
              <!-- Headers -->
              <div class="row align-items-center">
                <div class="col-4 p-4">
                  <strong class="h6">Teams</strong>
                </div>
                <div class="col-8 pl-8 pr-5">
                  <b-row no-gutters align-v="center" align-h="end">
                    <b-col v-for="(c, j) in m.choices" v-bind:key="`${idx}.${j}`" class="pr-2 text-center" cols="*" :sm="3">
                      <strong>{{ c.name }}</strong>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <!-- Teams -->
              <div v-for="(t, k) in m.teams" v-bind:key="`${idx}-${k}`" class="row align-items-center">
                <div class="col-4 p-2">
                  <b-row align-v="center" no-gutters>
                    <b-col cols="auto">
                      <div class="symbol symbol-30 symbol-light mr-3 flex-shrink-0">
                        <div class="symbol-label">
                          1
                        </div>
                      </div>
                    </b-col>
                    <b-col class="">
                      <strong>{{ t.name }}</strong>
                    </b-col>
                  </b-row>
                </div>
                <div class="col-8 pl-8 pr-5">
                  <b-row no-gutters align-v="center" align-h="end">
                    <b-col v-for="(c, j) in t.choices" v-bind:key="`${idx}-${k}-${j}`" class="pr-2" cols="*" :sm="3">
                      <b-card no-body class="p-2" v-on:click="openrate(m, c, t)">
                        <b-row align-v="center" no-gutters>
                          <b-col class="text-center">
                            <div>{{ c.rate }}</div>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- Vertically centered scrollable modal -->
    <b-modal v-model="setrate" content-class="shadow" hide-header hide-footer centered>
      <div>ทายผล</div>
      <div class="h4">คะแนนที่สามารถใช้ได้ <strong>{{ info.points }}</strong> คะแนน</div>
      <b-row class="my-8">
        <b-col :cols="10">
          {{ selected.team }}&nbsp;<strong>{{ selected.title }}</strong>
        </b-col>
        <b-col :cols="2">
          {{ selected.teamlogo }}
        </b-col>
      </b-row>
      <b-row no-gutters align-v="center">
        <b-col :cols="4" class="mr-4">จำนวนคะแนน</b-col>
        <b-col cols="auto" class="mr-2"><b-form-input v-model="setpoint" size="sm" style="width:90px" /></b-col>
        <b-col cols="auto">x {{ selected.rate }}</b-col>
      </b-row>
      <b-row no-gutters align-v="center" class="mt-4">
        <b-col :cols="4" class="mr-4">คะแนนที่ได้หากทายถูก</b-col>
        <b-col cols="auto"><strong>{{ calcpoint }} คะแนน</strong></b-col>
      </b-row>
      <b-row align-h="center" class="mt-8">
        <b-col cols="auto">
          <b-btn variant="primary" @click="submit">Submit</b-btn>
          <b-btn variant="light" class="ml-4" @click="cancel">Cancel</b-btn>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: "game_info",
  data () {
    return {
      info: {},
      points: {},
      matches: [],
      setrate: false,
      selected: {
        match: 0,
        choice: 0,
        title: '',
        team: '',
        teamlogo: '',
        rate: 0
      },
      setpoint: 0
    }
  },
  methods: {
    recvPoints() {
      this.axios.get(`/tournaments/${this.tour}/points`).then(
        ({ data }) => {
          this.info = data
        }
      )
    },
    recvData() {
      this.axios.get(`/tournaments/${this.tour}/predict`).then(
        ({ data }) => {
          this.matches = data.matches
          this.points = data.points
        }
      )
    },
    date (m) {
      return dayjs(m.time).format('DD/MM/YYYY')
    },
    time (m) {
      return dayjs(m.time).format('HH:mm')
    },
    padding (match) {
      return this.teams(match) ? 'pb-5' : ''
    },
    teams (match) {
      return match.teams != null && match.teams.length > 0
    },
    openrate (m, c, t) {
      var p = 0

      if (t != null) {
        this.selected.choice = p = (t.no * 100) + c.no
        this.selected.team = t.name
        this.selected.teamlogo = t.img
      } else {
        this.selected.choice = p = c.no
        this.selected.team = ''
        this.selected.teamlogo = ''
      }

      this.selected.title = c.name
      this.selected.match = m.no
      this.selected.rate = c.rate

      if (this.points[`m${m.no}`] != null && this.points[`m${m.no}`][`c${p}`] != null) {
        this.setpoint = this.points[`m${m.no}`][`c${p}`]
      } else {
        this.setpoint = 0
      }

      this.setrate = true
    },
    submit () {
      if (this.setpoint < 0) {
        return
      }

      var p = this.selected.choice
      var m = this.selected.match
      if (this.points[`m${m}`] != null && this.points[`m${m}`][`c${p}`] != null) {
        if (this.points[`m${m}`][`c${p}`] == this.setpoint) {
          return
        }
      }

      var params = {
        match: m,
        choice: p,
        pts: this.setpoint
      }

      this.axios.post(`/tournaments/${this.tour}/placepoint`, params).then(
        ({ data }) => {
          var c = data.choice
          var t = data.match
          if (this.points[`m${t}`] == null) {
            this.$set(this.points, `m${t}`, {})
          }

          this.$set(this.points[`m${t}`], `c${c}`, data.pts)
          this.cancel()

          this.info.points = data.ptsleft
        }
      )
    },
    cancel () {
      this.setrate = false
    }
  },
  computed: {
    tour() {
      return this.$route.params.tour
    },
    timezone () {
      return dayjs().format('Z')
    },
    calcpoint () {
      return this.selected.rate * this.setpoint
    }
  },
  mounted() {
    this.recvPoints()
    this.recvData()
  }
}
</script>

<style>

</style>